export const useSeoData = (seo: any) => {
    useSeoMeta({
        title: seo?.title,
        description: seo?.metaDesc,
        ogLocale: 'en_AU',
        ogUrl: seo?.opengraphUrl,
        ogTitle: seo?.opengraphTitle,
        ogSiteName: seo?.opengraphSiteName,
        ogDescription: seo?.opengraphDescription,
        ogType: seo?.opengraphType,
        ogImage: seo?.opengraphImage?.mediaItemUrl,
        ogImageType: seo?.opengraphImage?.mimeType,
        ogImageWidth: seo?.opengraphImage?.mediaDetails?.width,
        ogImageHeight: seo?.opengraphImage?.mediaDetails?.height,
        articleModifiedTime: seo?.opengraphModifiedTime,
        twitterCard: 'summary_large_image',
        twitterImage: seo?.twitterImage?.mediaItemUrl,
        twitterTitle: seo?.twitterTitle,
        twitterDescription: seo?.twitterDescription,
        robots: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
    });

    useHead({
        title: seo?.title,
        link: [{
            rel: 'canonical',
            href: seo?.canonical
        }],
        script: [{
            type: 'application/ld+json',
            innerHTML: seo?.schema?.raw
        }],
    });
};
