export default defineNuxtPlugin(() => {
    const navbar = document.getElementById('homepage-nav');
    const container = document.getElementById('page-container');

    if (process.client && (!navbar || !container)) {
        return;
    }

    const onScroll = () => {
        if (window.scrollY < (container.offsetTop - navbar.offsetHeight)) {
            navbar.classList.remove('bg-mage2');
        } else if (!navbar.classList.contains('bg-mage2')) {
            navbar.classList.add('bg-mage2');
        }
    };

    if (process.client && navbar) {
        onScroll();
        window.addEventListener('scroll', onScroll);
    }

    var anchors = document.getElementsByClassName('nav-link');
    for(var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];

        anchor.addEventListener('click', function(e) {
            e.preventDefault();
            const targetId = this.getAttribute('href');
            const targetElement = document.getElementById(targetId.replace('#', ''));

            if (targetElement) {
                const offset = 60; // Offset to account for the fixed navigation
    
                window.scrollTo({
                    top: targetElement.offsetTop - offset,
                    behavior: 'smooth'
                });
            }
        });
    }
});
