<script setup>
    import {useSeoData} from "~/composables/SeoHelper";
    const error = useError();

    if (error?.value?.statusCode === 404) {
        useSeoData({
            metaDesc: 'Page not found',
            title: 'Page not found'
        });
    } else {
        useSeoData({
            metaDesc: 'Something went wrong',
            title: 'Something went wrong'
        });
    }

</script>
<template>
  <NuxtLayout name="error">
    <div class="home-text d-flex align-items-center error-banner w-100 pt-5"></div>
    <div id="page-container" class="error-page container-xl">
        <div class="error-message">
            <template v-if="error.statusCode === 404">
                <h1>Page not found</h1>
            </template>
            <template v-else>
                <h1>Something went wrong</h1>
            </template>

            <a class="btn btn-secondary mt-3" href="/">Back to home</a>
        </div>
    </div>
  </NuxtLayout>
</template>