import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as indexS6Yq6tbw0dMeta } from "/app/pages/blog/[slug]/index.vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91page_93XjZzxmj3IbMeta } from "/app/pages/blog/page/[page].vue?macro=true";
import { default as index1mEL9i1OvLMeta } from "/app/pages/blog/page/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexS6Yq6tbw0dMeta?.name ?? "blog-slug",
    path: indexS6Yq6tbw0dMeta?.path ?? "/blog/:slug()",
    meta: indexS6Yq6tbw0dMeta || {},
    alias: indexS6Yq6tbw0dMeta?.alias || [],
    redirect: indexS6Yq6tbw0dMeta?.redirect,
    component: () => import("/app/pages/blog/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog",
    path: indexgWaMlevJ7RMeta?.path ?? "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93XjZzxmj3IbMeta?.name ?? "blog-page-page",
    path: _91page_93XjZzxmj3IbMeta?.path ?? "/blog/page/:page()",
    meta: _91page_93XjZzxmj3IbMeta || {},
    alias: _91page_93XjZzxmj3IbMeta?.alias || [],
    redirect: _91page_93XjZzxmj3IbMeta?.redirect,
    component: () => import("/app/pages/blog/page/[page].vue").then(m => m.default || m)
  },
  {
    name: index1mEL9i1OvLMeta?.name ?? "blog-page",
    path: index1mEL9i1OvLMeta?.path ?? "/blog/page",
    meta: index1mEL9i1OvLMeta || {},
    alias: index1mEL9i1OvLMeta?.alias || [],
    redirect: index1mEL9i1OvLMeta?.redirect,
    component: () => import("/app/pages/blog/page/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]